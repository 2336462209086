<template>
  <div class="demo-detail">
    <MobileSimulator :demo-url="demoUrl" />
  </div>
</template>

<script>
export default {
  name: "DemoDetail",
  data() {
    return {
      demoUrl: ""
    }
  },
  created() {
    this.demoUrl = this.$route.query.demoUrl
  }
}
</script>

<style lang="scss" scoped>
.demo-detail {
  ::v-deep .mobile-simulator {
    position: static;
    margin: 0 auto;
    height: 630px;
  }
}
</style>
